import "../Styles/Dashboard.css";
import { AiOutlineSearch } from "react-icons/ai";
import Navbar from "../Components/Navbar";
import { useTranslation } from "react-i18next";
import { IoCloseSharp } from "react-icons/io5";
import bckg from "../Images/order.svg";
import { RiShoppingCartFill } from "react-icons/ri";
import { useState, useEffect, useRef } from "react";
import { addToCart, awafiBalance } from "../api";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { search } from "../api";
import ErrorModal from "../Components/ErrorModal";

function AddToCart(props) {
  const { t, i18n } = useTranslation();
  const [size, setSize] = useState(0);
  const [err, setErr] = useState(false);
  const [selectPhoneOption, setSelectPhoneOption] = useState("with");
  const [isMealDetails, setIsMealDetails] = useState(false);
  const [phoneNum, setPhoneNum] = useState();

  const [selectedSizeObject, setSelectedSizeObject] = useState(
    props.data.sizes[0]
  );

  const phoneRef = useRef();
  let phoneRegEx =
    /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;

  const [selectedSize, setSelectedSize] = useState(props.data.sizes[0].id);
  const [quantity, setQuantity] = useState(1);
  let history = useNavigate();
  const [price, setPrice] = useState(
    props.data.sizes[0].isAdditionalRequired
      ? props.data.sizes[0].additionals[0].price
      : props.data.sizes[0].price
  );
  const [requiredAdd, setRequireAdd] = useState(
    props.data.sizes[0].isAdditionalRequired
  );
  const [errModal, setErrModal] = useState(false);
  const [arMsg, setArMsg] = useState();
  const [enMsg, setEnMsg] = useState();

  const [calories, setCalories] = useState(
    props.data.sizes[0].isAdditionalRequired
      ? props.data.sizes[0].additionals[0].calories
      : props.data.sizes[0].calories
  );
  const [availableQuan, setAvailableQuan] = useState(
    props.data.sizes[0].isAdditionalRequired
      ? props.data.sizes[0].additionals[0].quantity
      : props.data.sizes[0].quantity
  );
  const [mealData, setMealData] = useState({});
  const [disableButton, setDisableButton] = useState(
    props.data.sizes[0].isAdditionalRequired
      ? !(
          props.data.sizes[0].additionals[0].canAddToCart &&
          props.data.sizes[0].additionals[0].quantity >= 1
        )
      : !(props.data.sizes[0].canAddToCart && props.data.sizes[0].quantity >= 1)
  );
  const [selectedAdditional, setSelectedAdditional] = useState(
    props.data.sizes[0].isAdditionalRequired
      ? props.data.sizes[0].additionals[0].id
      : null
  );
  const [selectedAdditionalobj, setSelectedAdditionalobj] = useState(
    props.data.sizes[0].isAdditionalRequired
      ? props.data.sizes[0].additionals[0].id
      : null
  );

  const [selectedAdditionalIndex, setSelectedAdditionalIndex] = useState(
    props.data.sizes[0].isAdditionalRequired ? 0 : null
  );

  const [changeQuan, setChangeQuan] = useState(false);

  const [chosenExtras, setChoseExtras] = useState({
    selectedExtras: [],
    selectedExtrasNameAr: [],
    selectedExtrasNameEn: [],
  });

  // State to handle new radio buttons
  const [radioButtonData, setRadioButtonData] = useState({});

  var today = new Date();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();
  let day = today.getDate();
  let hour = today.getHours();
  let minute = today.getMinutes();
  let second = today.getSeconds();
  var firstPeriodTimeFrom = props.data.firstPeriodTimeFrom;
  var firstPeriodTimeTo = props.data.firstPeriodTimeTo;
  var secondPeriodTimeFrom = props.data.secondPeriodTimeFrom;
  var secondPeriodTimeTo = props.data.secondPeriodTimeTo;

  var now = new Date(year, month, day, hour, minute, second);
  var firstOnept1 = new Date(
    year,
    month,
    day,
    firstPeriodTimeFrom.slice(0, 2),
    firstPeriodTimeFrom.slice(3, 5),
    firstPeriodTimeFrom.slice(6, 8)
  );
  var firstOnept2 = new Date(
    year,
    month,
    day,
    firstPeriodTimeTo.slice(0, 2),
    firstPeriodTimeTo.slice(3, 5),
    firstPeriodTimeTo.slice(6, 8)
  );
  var secondOnept1 = new Date(
    year,
    month,
    day,
    secondPeriodTimeFrom.slice(0, 2),
    secondPeriodTimeFrom.slice(3, 5),
    secondPeriodTimeFrom.slice(6, 8)
  );
  var secondOnept2 = new Date(
    year,
    month,
    day,
    secondPeriodTimeTo.slice(0, 2),
    secondPeriodTimeTo.slice(3, 5),
    secondPeriodTimeTo.slice(6, 8)
  );

  function HandleAddToCartOffline() {
    const customerId = localStorage.getItem("customerId");
    const userId = localStorage.getItem("userId");
    const isAuthenticated = customerId !== null || userId !== null;

    // If not authenticated, return or handle it accordingly
    if (!isAuthenticated) {
      // Handle not authenticated case, maybe show a message to log in
      return;
    }

    let totalPrice = parseFloat(props.data.price) || 0;

    // Find the selected size and add its price to the total price
    const size = props.data.sizes
      ? props.data.sizes.find((size) => size.id === selectedSize)
      : null;
    if (size) {
      totalPrice += parseFloat(size.price) || 0;

      // Check if the selected size requires additionals and add their price
      if (size.additionals && selectedAdditional) {
        const additional = size.additionals.find(
          (additional) => additional.id === selectedAdditional
        );
        if (additional) {
          totalPrice = parseFloat(additional.price) || 0;
        } else {
          console.warn(
            "Selected additional not found or invalid:",
            selectedAdditional
          );
        }
      }
    } else {
      console.warn("Selected size not found or invalid:", selectedSize);
    }

    console.log(totalPrice);
    // Construct data object
    const data = {
      branchId: parseInt(localStorage.getItem("branchId")),
      typeId: parseInt(localStorage.getItem("orderType")),
      addressId:
        localStorage.getItem("orderType") === "2"
          ? parseInt(localStorage.getItem("addressId"))
          : null,
      platform: "agent",
      appVersion: "1.0.0",
      productId: props.data.id,
      quantity: quantity,
      userId: customerId !== null ? customerId : userId,
      sizeId: selectedSize,
      sized: selectedSizeObject,
      selectedAddtionObj: selectedAdditionalobj,

      additionals: selectedAdditional == null ? [] : [selectedAdditional],
      extras: chosenExtras.selectedExtras.sort(),
      accountType: localStorage.getItem("type"),
      arCurrency: props.data.arCurrency,
      arDescription: props.data.arDescription,
      arName: props.data.arName,
      category: props.data.category,
      active: props.data.active,
      applications: props.data.applications,
      featured: props.data.featured,
      featuredImage: props.data.featuredImage,
      id: props.data.id,
      image: props.data.image,
      price: totalPrice, // Ensure the calculated price is used
      name: props.data.name,
      nameTranslations: props.data.nameTranslations,
      popupMessageAr: props.data.popupMessageAr,
      popupMessageEn: props.data.popupMessageEn,
      popupShow: props.data.popupShow,
      products: props.data.products,
      description: props.data.description,
      enCurrency: props.data.enCurrency,
      enDescription: props.data.enDescription,
      enName: props.data.enName,
      firstPeriodTimeFrom: props.data.firstPeriodTimeFrom,
      firstPeriodTimeTo: props.data.firstPeriodTimeTo,
      secondPeriodTimeFrom: props.data.secondPeriodTimeFrom,
      secondPeriodTimeTo: props.data.secondPeriodTimeTo,
      sizes: props.data.sizes,
      calories: props.data.calories,
      canAddToCart: props.data.canAddToCart,
      isAdditionalRequired: props.data.isAdditionalRequired,
      isDefault: props.data.isDefault,
      sizeFactor: props.data.sizeFactor,
      typeArName: props.data.typeArName,
      typeEnName: props.data.typeEnName,
      typeId: props.data.typeId,
    };

    // Add data to localStorage
    let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

    // Check if the item already exists in the cart
    const existingItemIndex = cartItems.findIndex(
      (item) =>
        item.productId === data.productId &&
        item.sizeId === data.sizeId &&
        JSON.stringify(item.additionals) === JSON.stringify(data.additionals)
    );

    if (existingItemIndex > -1) {
      // Item exists, update the quantity
      cartItems[existingItemIndex].quantity += data.quantity;
    } else {
      // Item doesn't exist, add it to the cart
      cartItems.push(data);
    }

    localStorage.setItem("cartItems", JSON.stringify(cartItems));

    // Handle success (you may need to adjust this based on your requirements)
    props.setLoading(true);
    props.setAdd(!props.add);
    setDisableButton(false);
  }

  useEffect(() => {
    setIsMealDetails(true);
    window.scrollTo(0, 0);
    if (localStorage.getItem("now") == "false") {
      const date = localStorage.getItem("orderDate");
      const time = localStorage.getItem("orderTime");

      const laterDate = new Date(
        date.slice(0, 4),
        date.slice(5, 7),
        date.slice(8, 10),
        time.slice(0, 2),
        time.slice(3, 5),
        time.slice(6, 8)
      );

      if (firstOnept1 <= now && now <= firstOnept2) {
        if (!(firstOnept1 <= laterDate && firstOnept2 >= laterDate)) {
          setDisableButton(false);
        }
      } else {
        if (!(secondOnept1 <= laterDate && secondOnept2 >= laterDate)) {
          setDisableButton(false);
        }
      }
    }
  }, [changeQuan, size]);

  function handlePhone(e) {
    let newStr = e.target.value;
    let arr = [];
    for (let i = 0; i < newStr.length; i++) {
      if (!isNaN(newStr[i]) && newStr[i] !== " ") {
        arr.push(newStr[i]);
      }
    }

    setPhoneNum(arr.join(""));
    if (!arr.join("").match(phoneRegEx) && !arr.join("").toString() == "") {
    } else if (
      arr.join("").match(phoneRegEx) &&
      arr.join("").toString() == ""
    ) {
    }
  }

  const showError = (arMsg, enMsg) => {
    setErrModal(true);
    setArMsg(arMsg);
    setEnMsg(enMsg);
    props.setLoading(false);
    setTimeout(() => {
      setErrModal(false);
    }, 2000);
  };
  function handleSearchCustomer() {
    if (phoneNum == null || !phoneNum.match(phoneRegEx)) {
      return;
    } else if (phoneNum !== null) {
      props.setLoading(true);
      const token = localStorage.getItem("token");

      let data = {
        phone: phoneRef.current.value,
      };

      search(token, data)
        .then((response) => {
          if (response.data.message === "success") {
            if (response.data.data.length > 0) {
              localStorage.setItem("customerId", response.data.data[0].id);
              localStorage.setItem("customerName", response.data.data[0].name);
              localStorage.setItem(
                "customerNotes",
                response.data.data[0].notes
              );
              localStorage.setItem(
                "customerPhone",
                response.data.data[0].phone
              );
              localStorage.setItem(
                "customerPhoto",
                response.data.data[0].photo
              );
              localStorage.setItem(
                "customerAwafi",
                response.data.data[0].joinLoyalty
              );
              localStorage.setItem(
                "accountType",
                response.data.data[0].profileType == "Company User"
                  ? true
                  : false
              );
              localStorage.setItem(
                "organizationId",
                response.data.data[0].userOrganizationId
              );
              props.setLoading(false);
              setIsMealDetails(true);
            } else {
              localStorage.setItem("customerId", "");
              localStorage.setItem("customerName", "");
              localStorage.setItem("customerNotes", "");
              localStorage.setItem("customerPhone", JSON.stringify(data));
              localStorage.setItem("customerPhoto", "");
              localStorage.setItem("customerAwafi", "");
              history("/add-customer");
            }
          }
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            props.setLoading(false);
          } else if (error.response.status === 403) {
            props.setErr(true);
            props.setMsg(t("validation.noPermissionAction"));
            props.setStyle({ top: "50%" });

            setTimeout(function () {
              props.setErr(false);
              props.setLoading(false);
            }, 2500);
          } else if (error.response.status === 401) {
            history("/");
          } else {
            props.setLoading(false);
          }
        });
    }
  }

  const handleKeypress = (e) => {
    if (e.key == "Enter") {
      handleSearchCustomer();
    }
  };

  function handleSizeChange1(
    id,
    index,
    additionals,
    isAdditionalRequired,
    price,
    calories,
    arName,
    enName,
    quantity
  ) {
    setQuantity(1);
    setSelectedSize(id);
    selectedSizeObject({
      id,
      index,
      additionals,
      isAdditionalRequired,
      price,
      calories,
      arName,
      enName,
      quantity,
    });
    setSize(index);

    var elements = document.getElementsByName("mealAdditional");
    console.log(sized);

    for (var i = 0; i < elements.length; i++) {
      elements[i].checked = false;
    }
    setRequireAdd(isAdditionalRequired);
    if (isAdditionalRequired === true) {
      setCalories(props.data.sizes[index].additionals[0].calories);

      setAvailableQuan(props.data.sizes[index].additionals[0].quantity);

      setDisableButton(
        !(
          props.data.sizes[index].additionals[0].canAddToCart &&
          props.data.sizes[index].additionals[0].quantity >= 1
        )
      );
      setPrice(props.data.sizes[index].additionals[0].price);
      setSelectedAdditional(props.data.sizes[index].additionals[0].id);
      setSelectedAdditionalobj(props.data.sizes[index].additionals[0]);

      setSelectedAdditionalIndex(0);
      for (var i = 0; i < elements.length; i++) {
        if (i == 0) {
          elements[i].checked = true;
        }
      }
    } else {
      setSelectedAdditional(null);
      setSelectedAdditional(null);

      setSelectedAdditionalIndex(null);
      setPrice(parseFloat(price).toFixed(2));
      setCalories(calories);
      setAvailableQuan(quantity);
      setDisableButton(
        !(
          props.data.sizes[index].canAddToCart &&
          props.data.sizes[index].quantity >= 1
        )
      );
      setSelectedAdditional("");
    }

    setMealData({
      selectedOptionSize: id,
      selectedSize: id,
      sizeAr: arName,
      sizeEn: enName,
      selectedSizeNameAr: arName,
      selectedSizeNameEn: enName,
      mealAdditionals: [],
    });
  }

  function handleSizeChange(
    id,
    index,
    additionals,
    isAdditionalRequired,
    price,
    calories,
    arName,
    enName,
    quantity
  ) {
    setSelectedAdditional(null);
    setSelectedAdditionalobj(null);
    console.log(isAdditionalRequired);

    setQuantity(1);
    setSelectedSize(id);
    setSize(index);
    setSelectedSizeObject({
      id,
      index,
      additionals,
      isAdditionalRequired,
      price,
      calories,
      arName,
      enName,
      quantity,
    });

    var elements = document.getElementsByName("mealAdditional");

    console.log(isAdditionalRequired);

    for (var i = 0; i < elements.length; i++) {
      elements[i].checked = false;
    }
    setRequireAdd(isAdditionalRequired);

    if (isAdditionalRequired === true && additionals.length > 0) {
      const firstAdditional = additionals[0];

      // Automatically select the first additional
      setCalories(firstAdditional.calories);
      setAvailableQuan(firstAdditional.quantity);
      setPrice(firstAdditional.price);
      setSelectedAdditional(firstAdditional.id);
      setSelectedAdditionalobj(firstAdditional);
      setSelectedAdditionalIndex(0);
      elements[0].checked = true;

      setDisableButton(
        !(firstAdditional.canAddToCart && firstAdditional.quantity >= 1)
      );
    } else {
      setSelectedAdditional(null);
      setSelectedAdditionalIndex(null);
      setPrice(parseFloat(price).toFixed(2));
      setCalories(calories);
      setAvailableQuan(quantity);
      setDisableButton(
        !(
          props.data.sizes[index].canAddToCart &&
          props.data.sizes[index].quantity >= 1
        )
      );
      setSelectedAdditional("");
    }

    setMealData({
      selectedOptionSize: id,
      selectedSize: id,
      sizeAr: arName,
      sizeEn: enName,
      selectedSizeNameAr: arName,
      selectedSizeNameEn: enName,
      mealAdditionals: [],
    });
  }

  function handleAdditionalChange(
    id,
    price,
    calories,
    arName,
    enName,
    quantity,
    e,
    index,
    canAddToCart
  ) {
    setPrice(parseFloat(price).toFixed(2));
    setQuantity(1);
    console.log("hey");

    setSelectedAdditional(id);
    setSelectedAdditionalobj({
      id,
      price,
      calories,
      arName,
      enName,
      quantity,

      index,
      canAddToCart,
    });

    setSelectedAdditionalIndex(index);

    console.log(id);

    setCalories(calories);
    setAvailableQuan(quantity);

    if (quantity === 0 || canAddToCart == false) {
      setDisableButton(true);
      setChangeQuan(!changeQuan);
    } else {
      setDisableButton(false);
    }
  }

  function handleExtras(e) {
    var checkboxes = document.getElementsByName("mealExtra");
    var arNamearr = [];
    var enNamearr = [];

    var idNamearr = [];

    for (var i = 0, max = checkboxes.length; i < max; i += 1) {
      if (checkboxes[i].type === "checkbox" && checkboxes[i].checked) {
        arNamearr.push(JSON.parse(checkboxes[i].value).selectedExtrasNameAr);
        enNamearr.push(JSON.parse(checkboxes[i].value).selectedExtrasNameEn);
        idNamearr.push(JSON.parse(checkboxes[i].value).selectedExtras);
      }
    }

    setChoseExtras({
      selectedExtras: idNamearr,
      selectedExtrasNameAr: arNamearr,
      selectedExtrasNameEn: enNamearr,
    });
  }
  function transformString(str) {
    console.log(str);
    if (!str) return str;
    let newStr = str.charAt(0).toUpperCase() + str.slice(1);
    if (newStr.endsWith("s")) {
      newStr = newStr.slice(0, -1);
    }
    console.log(newStr);
    return newStr;
  }

  function HandleAddToCart() {
    const token = localStorage.getItem("token");
    setDisableButton(true);

    let data = {
      branchId: parseInt(localStorage.getItem("branchId")),
      typeId: parseInt(localStorage.getItem("orderType")),
      addressId:
        localStorage.getItem("orderType") == "2"
          ? parseInt(localStorage.getItem("addressId"))
          : null,
      platform: "agent",
      appVersion: "1.0.0",
      productId: props.data.id,
      quantity: quantity,
      userId:
        localStorage.getItem("customerId") === null
          ? localStorage.getItem("userId")
          : localStorage.getItem("customerId"),
      sizeId: selectedSize,

      additionalId: selectedAdditional,
      extras: chosenExtras.selectedExtras.sort(),
      accountType: localStorage.getItem("type"),
    };

    const removeNulls = (obj) => {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null) {
          delete obj[key];
        }
      });
    };

    removeNulls(data);

    addToCart(token, data)
      .then((response) => {
       
        // awafiBalance(localStorage.getItem("token"), localStorage.getItem("userPhone"))
        //         .then((res) => {
                  
        //           localStorage.setItem(
        //             "userAwaifiBalance",
        //             res.data.data.expirableWalletBalance
        //           );
        //         })
        //         .catch((err) => {
        //           console.log(err);
        //         });
        localStorage.removeItem("offer");

        props.setLoading(true);
        props.setAdd(!props.add);
        setDisableButton(false);
      })
      .catch(function (error) {
        setDisableButton(false);
        if (error.response.status === 401) {
          history("/");
        } else if (error.response.status === 403) {
          props.setErr(true);
          props.setMsg(t("validation.noPermissionAction"));
          props.setStyle({ top: "50%" });
          props.setModal(false);

          setTimeout(function () {
            props.setErr(false);
          }, 2500);
        } else {
          setErr(true);
          setTimeout(function () {
            // window.location.reload();
          }, 1200);
        }
      });
  }

  function handleQuantity(e, type) {
    if (type !== "btn") {
      if (
        !e.includes("٠") &&
        !e.includes("١") &&
        !e.includes("٢") &&
        !e.includes("٣") &&
        !e.includes("٤") &&
        !e.includes("٥") &&
        !e.includes("٦") &&
        !e.includes("٧") &&
        !e.includes("٨") &&
        !e.includes("٩")
      ) {
        let oldQuantity = quantity;
        if (e < 1) {
          setQuantity(oldQuantity);
        } else {
          setQuantity(e);

          if (
            e >
            (props.data.sizes[size].isAdditionalRequired
              ? props.data.sizes[size].additionals[selectedAdditionalIndex]
                  .quantity
              : selectedAdditionalIndex !== null
              ? props.data.sizes[size].additionals[selectedAdditionalIndex]
                  .quantity
              : props.data.sizes[size].quantity)
          ) {
            setDisableButton(true);
            setChangeQuan(!changeQuan);
          } else {
            setDisableButton(false);
          }
        }
      }
    } else {
      setQuantity(e);
      if (
        e >
        (props.data.sizes[size].isAdditionalRequired
          ? props.data.sizes[size].additionals[selectedAdditionalIndex].quantity
          : selectedAdditionalIndex !== null
          ? props.data.sizes[size].additionals[selectedAdditionalIndex].quantity
          : props.data.sizes[size].quantity)
      ) {
        setDisableButton(true);
        setChangeQuan(!changeQuan);
      } else {
        setDisableButton(false);
      }
    }
  }

  function handleUncheck(index) {
    setSelectedAdditional(null);
    setSelectedAdditionalobj(null);

    let radio = document.getElementsByName("mealAdditional");

    if (selectedAdditionalIndex == index && radio[index].checked) {
      radio[index].checked = false;

      setSelectedAdditionalIndex(null);
      setPrice(props.data.sizes[size].price);
      setCalories(props.data.sizes[size].calories);
      setAvailableQuan(props.data.sizes[size].quantity);
      setQuantity(1);
      setSelectedAdditional(null);
      setDisableButton(
        !(
          props.data.sizes[size].canAddToCart &&
          props.data.sizes[size].quantity >= 1
        )
      );
    }
  }

  // Render radio button groups

  return (
    <div>
      <div onClick={() => props.setModal(false)} className="modalOverlay"></div>
      <div
        className="modal-content"
        style={{
          height: "auto",
          width: "90vw ",
          maxWidth: "720px",
          paddingTop: "1em",
          top: `8%`,
          transform: "translateX(-50%)",
        }}
      >
        {err ? (
          <p className="noAval">
            {i18n.language === "ar"
              ? "هذه الوجبة غير متاحة"
              : "This meal is not available"}
          </p>
        ) : isMealDetails ? (
          <>
            <p className="mealDetail">{t("menu.mealDetails")}</p>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-8 col-7">
                  <p className="meal-name">
                    {i18n.language === "ar"
                      ? props.data.arName
                      : props.data.enName}
                  </p>
                  <p className="meal-detail">
                    {i18n.language === "ar"
                      ? props.data.arDescription
                      : props.data.enDescription}
                  </p>

                  <p className="meal-size">{t("menu.size")}</p>
                  <div className="row">
                    {props.data.sizes &&
                      props.data.sizes.map((data, index) => (
                        <div
                          key={index}
                          className={
                            requiredAdd ? "col-sm-6 " : "col-12 flexPrice"
                          }
                        >
                          <div className="flexOption">
                            <input
                              type="radio"
                              name="mealSize"
                              id={"size" + data.id}
                              defaultChecked={index === 0}
                              onChange={() =>
                                handleSizeChange(
                                  data.id,
                                  index,
                                  data.additionals,
                                  data.isAdditionalRequired,
                                  data.price,
                                  data.calories,
                                  data.arName,
                                  data.enName,
                                  data.quantity
                                )
                              }
                            />
                            <label
                              htmlFor={"size" + data.id}
                              style={
                                i18n.language === "ar"
                                  ? { marginRight: "0.5em" }
                                  : { marginLeft: "0.5em" }
                              }
                            >
                              {i18n.language === "ar"
                                ? data.arName
                                : data.enName}
                            </label>
                          </div>

                          {!data.isAdditionalRequired && !requiredAdd ? (
                            <p>
                              {data.price}{" "}
                              {i18n.language === "ar"
                                ? props.data.arCurrency
                                : props.data.enCurrency}
                            </p>
                          ) : null}
                        </div>
                      ))}
                  </div>

                  <div className="additionalWeb">
                    {props.data.sizes[size].additionals.length > 0 ? (
                      <>
                        <p style={{ marginTop: "1em" }} className="meal-size">
                          {t("menu.additionals")}
                        </p>
                        <div className="row">
                          {props.data.sizes[size].additionals &&
                            props.data.sizes[size].additionals.map(
                              (data, index) => (
                                <div className="col-12 flexPrice" key={index}>
                                  <div className="flexOption">
                                    <input
                                      onClick={
                                        requiredAdd
                                          ? null
                                          : () => handleUncheck(index)
                                      }
                                      type="radio"
                                      defaultChecked={
                                        requiredAdd ? index === 0 : false
                                      }
                                      name="mealAdditional"
                                      className="mealAdditional"
                                      id={"additional" + data.id}
                                      onChange={(e) =>
                                        handleAdditionalChange(
                                          data.id,
                                          data.price,
                                          data.calories,
                                          data.arName,
                                          data.enName,
                                          data.quantity,
                                          e,
                                          index,
                                          data.canAddToCart
                                        )
                                      }
                                    />

                                    <label
                                      htmlFor={"additional" + data.id}
                                      style={
                                        i18n.language === "ar"
                                          ? { marginRight: "0.5em" }
                                          : { marginLeft: "0.5em" }
                                      }
                                    >
                                      {i18n.language === "ar"
                                        ? data.arName
                                        : data.enName}
                                    </label>
                                  </div>
                                  <p>
                                    {data.price}{" "}
                                    {i18n.language === "ar"
                                      ? props.data.arCurrency
                                      : props.data.enCurrency}
                                  </p>
                                </div>
                              )
                            )}
                        </div>
                      </>
                    ) : null}

                    {props.data.extras.length > 0 ? (
                      <>
                        <p style={{ marginTop: "1em " }} className="meal-size">
                          {t("menu.extras")}
                        </p>
                        <div className="row">
                          {props.data.extras &&
                            props.data.extras.map((data, index) => (
                              <div className="col-6 flexOption" key={index}>
                                <input
                                  value={JSON.stringify({
                                    selectedExtras: data.id,
                                    selectedExtrasNameAr: data.arName,
                                    selectedExtrasNameEn: data.enName,
                                  })}
                                  type="checkbox"
                                  name="mealExtra"
                                  id={"extra" + data.id}
                                  onChange={(e) => handleExtras(e)}
                                />
                                <label
                                  htmlFor={"extra" + data.id}
                                  style={
                                    i18n.language === "ar"
                                      ? { marginRight: "0.5em" }
                                      : { marginLeft: "0.5em" }
                                  }
                                >
                                  {i18n.language === "ar"
                                    ? data.arName
                                    : data.enName}
                                </label>
                              </div>
                            ))}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>

                <div className="col-sm-4 col-5">
                  <div
                    className="meal-img"
                    style={{ backgroundImage: `url(${props.data.image})` }}
                  ></div>
                  <p className="cal">{t("menu.totalCalories")}</p>
                  <p className="calTotal">
                    {calories} {t("menu.calorie")}
                  </p>

                  <p className="calTotal">
                    <span style={{ fontWeight: "bolder" }}>
                      {localStorage.getItem("type") == "customerService"
                        ? t("menu.availableForCust")
                        : t("menu.available")}
                    </span>{" "}
                    {availableQuan}
                  </p>
                </div>
              </div>

              <div className="mobileDiv">
                {props.data.sizes[size].additionals.length > 0 ? (
                  <>
                    <p style={{ marginTop: "1em" }} className="meal-size">
                      {t("menu.additionals")}
                    </p>
                    <div className="row">
                      {props.data.sizes[size].additionals &&
                        props.data.sizes[size].additionals.map(
                          (data, index) => (
                            <div className="col-12 flexPrice" key={index}>
                              <div className="flexOption">
                                <input
                                  onClick={
                                    requiredAdd
                                      ? null
                                      : () => handleUncheck(index)
                                  }
                                  type="radio"
                                  defaultChecked={
                                    requiredAdd && window.innerWidth <= 575
                                      ? index === 0
                                      : false
                                  }
                                  name="mealAdditional"
                                  className="mealAdditional"
                                  id={"additional" + data.id}
                                  onChange={(e) =>
                                    handleAdditionalChange(
                                      data.id,
                                      data.price,
                                      data.calories,
                                      data.arName,
                                      data.enName,
                                      data.quantity,
                                      e,
                                      index,
                                      data.canAddToCart
                                    )
                                  }
                                />

                                <label
                                  htmlFor={"additional" + data.id}
                                  style={
                                    i18n.language === "ar"
                                      ? { marginRight: "0.5em" }
                                      : { marginLeft: "0.5em" }
                                  }
                                >
                                  {i18n.language === "ar"
                                    ? data.arName
                                    : data.enName}
                                </label>
                              </div>
                              <p>
                                {data.price}{" "}
                                {i18n.language === "ar"
                                  ? props.data.arCurrency
                                  : props.data.enCurrency}
                              </p>
                            </div>
                          )
                        )}
                    </div>
                  </>
                ) : null}

                {props.data.extras.length > 0 ? (
                  <>
                    <p style={{ marginTop: "1em " }} className="meal-size">
                      {t("menu.extras")}
                    </p>
                    <div className="row">
                      {props.data.extras &&
                        props.data.extras.map((data, index) => (
                          <div className="col-6 flexOption" key={index}>
                            <input
                              value={JSON.stringify({
                                selectedExtras: data.id,
                                selectedExtrasNameAr: data.arName,
                                selectedExtrasNameEn: data.enName,
                              })}
                              type="checkbox"
                              name="mealExtra"
                              id={"extra" + data.id}
                              onChange={(e) => handleExtras(e)}
                            />
                            <label
                              htmlFor={"extra" + data.id}
                              style={
                                i18n.language === "ar"
                                  ? { marginRight: "0.5em" }
                                  : { marginLeft: "0.5em" }
                              }
                            >
                              {i18n.language === "ar"
                                ? data.arName
                                : data.enName}
                            </label>
                          </div>
                        ))}
                    </div>
                  </>
                ) : null}
              </div>

              <div style={{ marginTop: "1em" }} className="row mealBottom">
                <div className="col-sm-4 order-sm-first order-last">
                  <button
                    className="addMeal"
                    disabled={disableButton}
                    onClick={() => {
                      const userType = localStorage.getItem("type");
                      if (userType === "cashier") {
                        HandleAddToCartOffline();
                      } else {
                        HandleAddToCart();
                      }
                    }}
                  >
                    {localStorage.getItem("type") == "customerService"
                      ? t("menu.addToCartForCust")
                      : t("menu.addToCart")}{" "}
                    <RiShoppingCartFill />
                  </button>
                </div>
                <div className="col-sm-5 col-9 quanBtns">
                  <p>{t("menu.quanitity")} </p>
                  <button
                    className="quantity-btn1"
                    onClick={() =>
                      handleQuantity(parseInt(quantity) + 1, "btn")
                    }
                  >
                    +
                  </button>
                  <input
                    value={quantity}
                    onChange={(e) => handleQuantity(e.target.value)}
                  />
                  <button
                    className="quantity-btn2"
                    onClick={() =>
                      quantity > 1
                        ? handleQuantity(parseInt(quantity) - 1, "btn")
                        : null
                    }
                  >
                    -
                  </button>
                </div>
                <div className="col-sm-3 col-3">
                  <p style={{ marginBottom: 0 }} className="total">
                    {parseFloat(price) * quantity}{" "}
                    {i18n.language === "ar"
                      ? props.data.arCurrency
                      : props.data.enCurrency}
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              height: selectPhoneOption !== "with" ? "20vh" : "60vh",

              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "20px",
            }}
          >
            {errModal ? (
              <ErrorModal
                setModal={setErrModal}
                msg={i18n.language === "ar" ? arMsg : enMsg}
              />
            ) : null}
            <div
              style={{
                alignContent: "start",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                position: "relative",
                justifyContent: "space-between",
                top: selectPhoneOption !== "with" ? "2vh" : "0px",
              }}
            >
              <p
                style={{
                  alignContent: "start",
                  width: "60%",
                  display: "flex",
                  fontFamily: "cairo",
                  fontWeight: "bold",
                }}
              >
                {t("pickorderway")}
              </p>
              <label
                style={{
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  style={{ margin: "5px" }}
                  type="radio"
                  value="with"
                  checked={selectPhoneOption === "with"}
                  onChange={() => {
                    setSelectPhoneOption("with");
                  }}
                />
                <span
                  style={{
                    color: selectPhoneOption === "with" ? "#e47100" : "initial",
                  }}
                >
                  {t("withPhone")}
                </span>
              </label>
              <label
                style={{
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  style={{ margin: "5px" }}
                  type="radio"
                  value="without"
                  checked={selectPhoneOption === "without"}
                  onChange={() => {
                    setSelectPhoneOption("without");
                    setIsMealDetails(true);
                  }}
                />
                <span
                  style={{
                    color:
                      selectPhoneOption === "without" ? "#e47100" : "initial",
                  }}
                >
                  {t("withoutPhone")}
                </span>
              </label>
            </div>

            {selectPhoneOption === "with" ? (
              <div
                style={{ width: "600px", marginTop: "5vh" }}
                className="searchCustomerInpopup"
              >
                {props.loading ? (
                  <div style={{ height: "10vh" }} className="loader">
                    <BeatLoader
                      color={"#E47100"}
                      loading={props.loading}
                      size={30}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label
                      style={{ display: "flex", alignItems: "flex-start" }}
                    >
                      {t("dashboard.enterPhone")}
                    </label>

                    <div
                      className="srchField"
                      style={{
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      <input
                        style={{ width: "25vw", height: "4vh" }}
                        type="string"
                        placeholder={t("dashboard.search")}
                        value={phoneNum}
                        id="phone"
                        name="phone"
                        ref={phoneRef}
                        required={true}
                        onInput={(e) => {
                          handlePhone(e);
                        }}
                        onKeyPress={handleKeypress}
                      />

                      <button
                        onClick={handleSearchCustomer}
                        style={
                          i18n.language === "ar"
                            ? null
                            : {
                                marginRight: "auto",
                                marginLeft: "1em",
                              }
                        }
                      >
                        {t("dashboard.search")}
                      </button>
                    </div>

                    <span className="validation"></span>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

export default AddToCart;
